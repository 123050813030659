.modern-system-and {
  position: absolute;
  top: 88.52px;
  /* left: 55.19px; */
  line-height: 51.39px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 637.05px; */
  height: 58.94px;
}

.heading-3 {
  position: absolute;
  top: -22.73px;
  left: 0;
  width: 100%;
  height: 178px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divscrolltop,
.list-item {
  position: absolute;
  top: 22.73px;
  left: 0;
  width: 736.8px;
  height: 117.89px;
  overflow: hidden;
}

.divscrolltop {
  top: 0.09px;
  width: 737px;
  height: 197px;
}

.enter-your-email {
  position: relative;
}

.divplaceholder {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 0 355.36492919921875px 0 0;
  align-items: flex-start;
  justify-content: flex-start;
}

.get-it-now,
.input {
  position: absolute;
  display: flex;
}

.input {
  top: 12.78px;
  left: calc(50% - 279px);
  border-radius: 30.23px;
  background-color: rgba(255, 255, 255, 0.95);
  flex-direction: row;
  padding: 12.84679126739502px 15.113872528076172px 13.602485656738281px;
  align-items: flex-start;
  justify-content: flex-start;
}

.get-it-now {
  top: 12.85px;
  left: 32.49px;
  line-height: 13.6px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  width: 79.85px;
  height: 18.89px;
}

.form-form-fieldset,
.input1 {
  position: absolute;
  overflow: hidden;
}

.input1 {
  width: calc(100% - 408.31px);
  top: 12.78px;
  right: 12.18px;
  left: 396.13px;
  border-radius: 0 30.23px 30.23px 0;
  background-color: var(--color-darkorange);
  border: 2.3px solid var(--color-darkorange);
  box-sizing: border-box;
  height: 45.34px;
  text-align: center;
  color: var(--color-white);
}

.form-form-fieldset {
  top: 147.09px;
  left: calc(50% - 276.47px);
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  width: 584px;
  height: 71px;
  display: none;
  text-align: left;
  font-size: var(--font-size-sm-6);
  color: #5c5c5c;
}

.imacpng-icon,
.image-7-icon {
  position: absolute;
  object-fit: cover;
}

.imacpng-icon {
  top: calc(50% - 296.99px);
  left: calc(50% - 366.51px);
  width: 733.02px;
  height: 593.98px;
}

.image-7-icon {
  top: 0;
  left: 0;
  width: 733px;
  height: 475.76px;
}

.divslider-wrapper {
  position: absolute;
  height: calc(100% - 224.98px);
  top: 28.37px;
  bottom: 196.6px;
  left: 29.07px;
  width: 673px;
  overflow: hidden;
}

.divhome-slider,
.divwpb-wrapper {
  position: absolute;
  top: 260.71px;
  left: calc(50% - 368.47px);
  width: 736.8px;
  height: 593.98px;
}

.divwpb-wrapper {
  top: 120.91px;
  height: 230.49px;
}

.powerful-software-with {
  position: absolute;
  top: 152.09px;
  /* left: 450.39px; */
  line-height: 51.39px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 60.46px;
}

.divhome-contain,
.section {
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.divhome-contain {
  top: 600px;
  left: 0;
  width: 100%;
  height: 730px;
  background-image: url(/public/img/Section_1.png);
}

.section {
  top: 114px;
  /* left: calc(50% - 725px); */
  width: 100%;
  height: 694px;
  background-image: url(/public/section@3x.png);
  text-align: center;
  font-size: 43.83px;
  color: var(--color-white);
  font-family: var(--font-open-sans);
}

.divelementor-background-overl {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-darkslategray-100);
  opacity: 0.6;
}

.heading-4,
.label-name {
  position: absolute;
  top: 33px;
  left: 37.5px;
  line-height: 31.5px;
  display: flex;
  align-items: center;
  width: 187.21px;
  height: 25.5px;
}

.label-name {
  top: -1.5px;
  left: 9.75px;
  line-height: 12px;
  font-weight: 600;
  width: 32.03px;
  height: 14.25px;
}

.divplaceholder1,
.name {
  position: absolute;
  height: 15.75px;
}

.name {
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 33.4px;
}

.divplaceholder1 {
  top: 9.46px;
  left: 12.75px;
  width: 141px;
  overflow: hidden;
  opacity: 0.6;
}

.input2 {
  position: absolute;
  width: calc(100% - 19.5px);
  top: calc(50% - 6.37px);
  right: 9.75px;
  left: 9.75px;
  border-radius: var(--br-8xs-5);
  background-color: var(--color-white);
  border: 0.8px solid var(--color-darkslategray-400);
  box-sizing: border-box;
  height: 35.25px;
  font-size: 12.75px;
  color: var(--color-darkslategray-300);
}

.divelementor-field-type-text {
  position: absolute;
  width: calc(100% - 186px);
  top: 0;
  right: 186px;
  left: 0;
  height: 57.75px;
}

.email,
.label-email {
  position: absolute;
  display: flex;
  align-items: center;
}

.label-email {
  top: -1.5px;
  left: 9.75px;
  line-height: 12px;
  font-weight: 600;
  width: 29.92px;
  height: 14.25px;
}

.email {
  top: 0;
  left: 0;
  width: 31.03px;
  height: 15.75px;
}

.divelementor-field-type-email {
  position: absolute;
  width: calc(100% - 186px);
  top: 0;
  right: 0;
  left: 186px;
  height: 57.75px;
}

.it-management-service,
.label-inquiry {
  position: absolute;
  display: flex;
  align-items: center;
}

.label-inquiry {
  top: -1.75px;
  left: 9.25px;
  line-height: 12px;
  font-weight: 600;
  width: 92px;
  height: 15px;
}

.it-management-service {
  top: 4.5px;
  left: 0;
  line-height: 24px;
  width: 123.74px;
  height: 14.25px;
}

.div {
  top: calc(50% - 12px);
  left: 12.75px;
  width: 138px;
  height: 24px;
  overflow: hidden;
}

.div,
.icon,
.options {
  position: absolute;
}

.options {
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  border-radius: var(--br-8xs-5);
  background-color: var(--color-white);
  border: 0.8px solid var(--color-darkslategray-400);
  box-sizing: border-box;
  height: 35.25px;
}

.icon {
  top: 10.13px;
  left: 150.85px;
  width: 8.15px;
  height: 14.25px;
  overflow: hidden;
}

.divelementor-field {
  position: absolute;
  width: calc(100% - 19.5px);
  top: calc(50% - 6.38px);
  right: 9.75px;
  left: 9.75px;
  height: 35.25px;
  color: var(--color-darkslategray-300);
}

.divelementor-field-type-selec {
  position: absolute;
  width: calc(100% - 186px);
  top: 83.25px;
  right: 186px;
  left: 0;
  height: 57.75px;
}

.label-inquiry1 {
  position: absolute;
  top: -2px;
  left: 10px;
  line-height: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 101px;
  height: 15px;
}

.divelementor-field-type-selec1 {
  position: absolute;
  width: calc(100% - 186px);
  top: 166.5px;
  right: 185.75px;
  left: 0.25px;
  height: 57.75px;
}

.input4,
.label-phone {
  position: absolute;
  left: 9.75px;
}

.label-phone {
  top: -1.5px;
  line-height: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 34.53px;
  height: 14.25px;
}

.input4 {
  width: calc(100% - 19.5px);
  top: calc(50% - 6.38px);
  right: 9.75px;
  border-radius: var(--br-8xs-5);
  background-color: var(--color-white);
  border: 0.8px solid var(--color-darkslategray-400);
  box-sizing: border-box;
  height: 35.25px;
  font-size: 12.75px;
  color: var(--color-darkslategray-300);
}

.divelementor-field-type-tel {
  position: absolute;
  width: calc(100% - 186px);
  top: 83.25px;
  right: 0;
  left: 186px;
  height: 57.75px;
}

.label-phone1,
.location {
  position: absolute;
  display: flex;
  align-items: center;
}

.label-phone1 {
  top: -2px;
  left: 10px;
  line-height: 12px;
  font-weight: 600;
  width: 63px;
  height: 15px;
}

.location {
  top: 0;
  left: 0;
  width: 84.74px;
  height: 15.75px;
}

.divelementor-field-type-tel1 {
  position: absolute;
  width: calc(100% - 186px);
  top: 166.5px;
  right: -0.25px;
  left: 186.25px;
  height: 57.75px;
}

.label-additional,
.textarea {
  position: absolute;
  left: 9.75px;
}

.label-additional {
  top: -1.5px;
  line-height: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 91.82px;
  height: 14.25px;
}

.textarea {
  width: calc(100% - 19.5px);
  top: calc(50% - 29.7px);
  right: 9.75px;
  border-radius: var(--br-8xs-5);
  background-color: var(--color-white);
  border: 0.8px solid var(--color-darkslategray-400);
  box-sizing: border-box;
  height: 81.89px;
  overflow: hidden;
}

.divelementor-field-type-texta {
  position: absolute;
  width: 100%;
  top: 249.5px;
  right: 0;
  left: 0;
  height: 104.39px;
}

.input6 {
  position: absolute;
  top: 6.75px;
  left: 0;
  border-radius: 1.88px;
  background-color: var(--color-white);
  border: 0.8px solid #767676;
  box-sizing: border-box;
  width: 9.75px;
  height: 9.75px;
}

.label-i {
  position: absolute;
  top: 5.96px;
  left: 12.07px;
  line-height: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 157.42px;
  height: 14.25px;
}

.spanelementor-field-option {
  position: absolute;
  top: 379.39px;
  left: 9.75px;
  width: 352.5px;
  height: 24px;
}

.submit-message {
  position: relative;
  letter-spacing: -0.38px;
  line-height: 12px;
  font-weight: 500;
}

.button,
.form {
  position: absolute;
}

.button {
  bottom: -57.5px;
  left: 9.75px;
  border-radius: 11.25px;
  background-color: var(--color-darkorange);
  display: flex;
  flex-direction: row;
  padding: 16.500001907348633px 129.22500610351562px 16.500001907348633px 128.77500915527344px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 30px;
  text-align: center;
  color: var(--color-white);
  font-family: var(--font-montserrat);
}

.form {
  top: 76.5px;
  left: 27.75px;
  width: 372px;
  height: 416.39px;
  font-size: var(--font-size-xs);
  font-family: var(--font-lato);
}

.divelementor-widget-wrap {
  position: absolute;
  width: calc(100% - 0.5px);
  top: 0;
  right: 0.5px;
  left: 0;
  height: 512.39px;
}

.divelementor-column-wrap {
  position: absolute;
  width: calc(100% + 1px);
  top: 0;
  right: -0.5px;
  left: -0.5px;
  border-radius: 11.25px;
  background-color: var(--color-white);
  height: 590px;
}

.divelementor-row {
  position: absolute;
  width: calc(100% - 1013px);
  top: 75px;
  right: 149px;
  left: 864px;
  height: 590px;
}

.section1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 737px;
  /* background-image: url(/public/section1@3x.png); */
  background-image: url(/public/Section3.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  font-size: 21px;
  color: var(--color-darkslategray-100);
}

.logo-ipsum-white01png-icon,
.logo-ipsum-white02png-icon,
.logo-ipsum-white03png-icon,
.logo-ipsum-white04png-icon {
  position: absolute;
  top: calc(50% - 44.15px);
  left: calc(50% - 510px);
  width: 240px;
  height: 88.31px;
  object-fit: cover;
  opacity: 0.5;
}

.logo-ipsum-white02png-icon,
.logo-ipsum-white03png-icon,
.logo-ipsum-white04png-icon {
  left: calc(50% - 250px);
}

.logo-ipsum-white03png-icon,
.logo-ipsum-white04png-icon {
  left: calc(50% + 10px);
}

.logo-ipsum-white04png-icon {
  left: calc(50% + 270px);
}

.section3 {
  position: absolute;
  width: calc(100% - 100px);
  top: 45px;
  right: 50px;
  left: 50px;
  height: 108.31px;
}

.section2 {
  position: absolute;
  top: 5277px;
  left: calc(50% - 570px);
  border-radius: 15px;
  background-color: var(--color-darkslategray-100);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 25px 75px rgba(0, 20, 91, 0.16);
  width: 1140px;
  height: 198.31px;
}

.img03png-icon {
  position: absolute;
  top: 0;
  left: 468.02px;
  width: 473.08px;
  height: 347.66px;
  object-fit: cover;
}

.streamline-your-business {
  position: absolute;
  top: 2.28px;
  left: 0.09px;
  line-height: 42px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 430px;
  height: 59px;
}

.heading-41,
.our-advanced-point {
  position: absolute;
  top: 52.69px;
  left: 0;
  width: 404.81px;
  height: 63.5px;
}

.our-advanced-point {
  top: 38.97px;
  line-height: 23.81px;
  font-weight: 300;
  display: flex;
  align-items: center;
  width: 367.15px;
  height: 62.71px;
}

.p,
.strong-about {
  position: absolute;
  top: 165.62px;
  left: 0;
  width: 404.81px;
  height: 71.44px;
  font-size: var(--font-size-xs);
  font-family: var(--font-open-sans);
}

.strong-about {
  top: -30px;
  left: 3px;
  font-size: var(--font-size-8xl);
  line-height: 36px;
  font-weight: 600;
  font-family: var(--font-montserrat);
  color: var(--color-darkorange);
  display: flex;
  align-items: center;
  width: 207px;
  height: 23px;
}

.divrow,
.divtxt-holder {
  position: absolute;
  top: 38.97px;
  left: -48.75px;
  width: 452px;
  height: 331px;
}

.divrow {
  top: 81.76px;
  left: 285.75px;
  width: 952.5px;
  height: 347.66px;
}

.section4 {
  position: absolute;
  top: 2350px;
  /* left: calc(50% - 762px); */
  width: 100%;
  height: 530.23px;
  background-image: url(/public/img/Section_2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  font-size: var(--font-size-16xl);
  color: var(--color-white);
  font-family: var(--font-jost);
}

.strong-about1 {
  top: -12px;
  left: 0;
  line-height: 36px;
  font-weight: 600;
  width: 207px;
  height: 23px;
}

.our-mission-is,
.strong-about1,
.we-can-help {
  position: absolute;
  display: flex;
  align-items: center;
}

.we-can-help {
  top: 29px;
  left: 0;
  font-size: var(--font-size-16xl);
  line-height: 42px;
  font-weight: 600;
  font-family: var(--font-jost);
  color: #1c375b;
  width: 591px;
  height: 50px;
}

.our-mission-is {
  top: 37px;
  left: 4px;
  line-height: 24px;
  width: 543.64px;
  height: 43px;
}

.p1,
.pseudo-icon {
  position: absolute;
  left: 0;
}

.p1 {
  top: 97.19px;
  width: 570px;
  height: 48px;
  font-size: var(--font-size-base);
  color: var(--color-slategray-100);
  font-family: var(--font-roboto);
}

.pseudo-icon {
  top: 225.19px;
  width: 63px;
  height: 10px;
  object-fit: cover;
}

.header {
  position: absolute;
  top: 0;
  left: 15px;
  width: 570px;
  height: 175.19px;
}

.clients-satisfaction,
.div3 {
  position: absolute;
  top: 6px;
  left: 14px;
  line-height: 12px;
  display: flex;
  align-items: center;
  width: 12.2px;
  height: 12px;
}

.clients-satisfaction {
  top: 2.81px;
  left: 35px;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--font-roboto);
  color: var(--color-slategray-100);
  width: 155px;
  height: 19px;
}

.item {
  position: absolute;
  width: calc(100% - 300px);
  top: 0;
  right: 300px;
  left: 0;
  height: 24px;
}

.strategic-planning {
  position: absolute;
  top: 1.81px;
  left: 35px;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--font-roboto);
  color: var(--color-slategray-100);
  display: flex;
  align-items: center;
  width: 228px;
  height: 19px;
}

.item1 {
  position: absolute;
  width: calc(100% - 300px);
  top: 0;
  right: 0;
  left: 300px;
  height: 24px;
}

.innovative-solutions {
  position: absolute;
  top: 1.81px;
  left: 35px;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--font-roboto);
  color: var(--color-slategray-100);
  display: flex;
  align-items: center;
  width: 175px;
  height: 19px;
}

.item2 {
  position: absolute;
  width: calc(100% - 300px);
  top: 35px;
  right: 300px;
  left: 0;
  height: 24px;
}

.continuous-improvement {
  position: absolute;
  top: 2px;
  left: 35px;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--font-roboto);
  color: var(--color-slategray-100);
  display: flex;
  align-items: center;
  width: 186.67px;
  height: 19px;
}

.item3 {
  position: absolute;
  width: calc(100% - 300px);
  top: 35px;
  right: 0;
  left: 300px;
  height: 24px;
}

.seamless-implementation {
  position: absolute;
  top: 1.81px;
  left: 35px;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--font-roboto);
  color: var(--color-slategray-100);
  display: flex;
  align-items: center;
  width: 198px;
  height: 19px;
}

.item4 {
  position: absolute;
  width: calc(100% - 300px);
  top: 70px;
  right: 300px;
  left: 0;
  height: 24px;
}

.ongoing-support {
  top: 2px;
  left: 35px;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-family: var(--font-roboto);
  color: var(--color-slategray-100);
  display: flex;
  align-items: center;
  width: 133.86px;
  height: 19px;
}

.item5,
.list,
.ongoing-support {
  position: absolute;
}

.item5 {
  width: calc(100% - 300px);
  top: 70px;
  right: 0;
  left: 300px;
  height: 24px;
}

.list {
  top: 270.19px;
  left: 0;
  width: 600px;
  height: 105px;
  font-size: var(--font-size-xs);
  color: var(--color-firebrick);
  font-family: var(--font-font-awesome-5-free);
}

.divcol-12 {
  position: absolute;
  width: calc(100% - 600px);
  top: 0;
  right: 0;
  left: 600px;
  height: 455px;
}

.image-1-icon {
  position: absolute;
  top: calc(50% - 222px);
  left: 0;
  border-radius: 20px;
  width: 496px;
  height: 460px;
  object-fit: cover;
}

.divimgholder {
  position: absolute;
  bottom: 0;
  left: 15px;
  width: 556px;
  height: 500px;
}

.article,
.how-it-works {
  position: absolute;
  top: 1800px;
  left: calc(50% - 600px);
  width: 1200px;
  height: 455px;
}

.how-it-works {
  top: -3px;
  left: calc(50% - 95px);
  line-height: 36px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 190px;
  height: 42px;
}

.heading-42 {
  position: absolute;
  top: 0;
  left: 15px;
  width: 1170px;
  height: 36px;
}

.discover-the-power {
  position: absolute;
  top: 4px;
  left: 356.45px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 475.63px;
  height: 47px;
}

.header1,
.p2 {
  position: absolute;
}

.p2 {
  top: 65px;
  left: 15px;
  width: 1170px;
  height: 56px;
  text-align: center;
  font-size: var(--font-size-sm);
  color: #8b939b;
  font-family: var(--font-open-sans);
}

.header1 {
  width: 100%;
  top: 124px;
  right: 0;
  left: 0;
  height: 121px;
}

.pseudo {
  top: 20px;
  left: 190px;
  border: 1px dashed #d7d9da;
  box-sizing: border-box;
  width: 790px;
  height: 2px;
}

.div9,
.pseudo,
.spannum {
  position: absolute;
}

.div9 {
  top: 12px;
  left: 18.44px;
  line-height: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.31px;
  height: 19px;
}

.spannum {
  top: 0;
  left: calc(50% - 21.51px);
  border-radius: var(--br-10xs);
  background-color: var(--color-darkslategray-200);
  width: 43px;
  height: 43px;
}

.icon04png {
  position: absolute;
  top: 87px;
  left: 168.47px;
  width: 53px;
  height: 53px;
  display: none;
}

.customers-choose-products,
.heading-31 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading-31 {
  top: 174px;
  left: 84px;
  font-size: var(--font-size-lg);
  line-height: 20px;
  font-weight: 600;
  color: var(--color-darkslategray-200);
  width: 222px;
  height: 22px;
}

.customers-choose-products {
  top: 5px;
  left: 7px;
  line-height: 26px;
  font-weight: 300;
  width: 336px;
  height: 45px;
}

.p3 {
  position: absolute;
  top: 210px;
  left: 20px;
  width: 349.95px;
  height: 51.97px;
  font-size: var(--font-size-sm);
  color: var(--color-darkgray-200);
  font-family: var(--font-open-sans);
}

.product-1-icon,
.receipt-1 {
  position: absolute;
  top: 84px;
  left: 268px;
  width: 53px;
  height: 53px;
  overflow: hidden;
}

.product-1-icon {
  top: 81px;
  left: 166px;
  width: 60px;
  height: 60px;
}

.item6 {
  position: absolute;
  width: calc(100% - 780.05px);
  top: 0;
  right: 780.05px;
  left: 0;
  height: 274.47px;
}

.div10 {
  top: 12px;
  left: 16.85px;
  line-height: 20px;
  font-weight: 600;
  width: 9.51px;
  height: 19px;
}

.customers-provide-payment,
.div10,
.heading-32 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading-32 {
  top: 177px;
  left: 137.41px;
  font-size: var(--font-size-lg);
  line-height: 20px;
  font-weight: 600;
  color: var(--color-darkslategray-200);
  width: 116.62px;
  height: 22px;
}

.customers-provide-payment {
  top: 14px;
  left: -17.59px;
  line-height: 26px;
  font-weight: 300;
  width: 385px;
  height: 45px;
}

.p4 {
  position: absolute;
  top: 213px;
  left: 20px;
  width: 349.95px;
  height: 51.97px;
  font-size: var(--font-size-sm);
  color: var(--color-darkgray-200);
  font-family: var(--font-open-sans);
}

.payment-system-1-icon {
  position: absolute;
  top: 81px;
  left: 165.41px;
  width: 60px;
  height: 60px;
  overflow: hidden;
}

.div11,
.item7 {
  position: absolute;
}

.item7 {
  width: calc(100% - 780.05px);
  top: 0;
  right: 390.46px;
  left: 389.59px;
  height: 277.47px;
}

.div11 {
  top: 12px;
  left: 16.84px;
  line-height: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9.51px;
  height: 19px;
}

.spannum2 {
  position: absolute;
  top: 0;
  left: calc(50% - 21.34px);
  border-radius: var(--br-10xs);
  background-color: var(--color-darkslategray-200);
  width: 43px;
  height: 43px;
}

.icon06png {
  position: absolute;
  top: 87px;
  left: 168.66px;
  width: 53px;
  height: 53px;
}

.after-successful-payment,
.heading-33 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading-33 {
  top: 174px;
  left: 82px;
  font-size: var(--font-size-lg);
  line-height: 20px;
  font-weight: 600;
  color: var(--color-darkslategray-200);
  width: 217px;
  height: 22px;
}

.after-successful-payment {
  top: 3px;
  left: -0.19px;
  line-height: 26px;
  font-weight: 300;
  width: 350px;
  height: 45px;
}

.p5 {
  position: absolute;
  top: 223px;
  left: 20.19px;
  width: 349.95px;
  height: 51.97px;
  font-size: var(--font-size-sm);
  color: var(--color-darkgray-200);
  font-family: var(--font-open-sans);
}

.group-icon {
  height: 19.17%;
  width: 13.28%;
  top: 25.88%;
  right: 43.13%;
  bottom: 54.95%;
  left: 43.59%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.group-icon,
.item8,
.list1 {
  position: absolute;
}

.item8 {
  width: calc(100% - 780px);
  top: 0;
  right: 1px;
  left: 779px;
  height: 313px;
}

.list1 {
  top: 306px;
  left: 15px;
  width: 1170px;
  height: 301px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-white);
}

.divprocess-holder {
  position: absolute;
  top: 2900px;
  left: calc(50% - 600px);
  width: 1200px;
  height: 687.47px;
  font-size: var(--font-size-9xl);
}

.divelementor-background-overl-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: -2px;
  bottom: 0;
  left: 2px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  opacity: 0.8;
}

.heading-5 {
  position: absolute;
  top: 206.75px;
  left: -0.49px;
  line-height: 36px;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 179px;
  height: 21px;
}

.enjoy-our {
  margin: 0;
}

.enjoy-our-special-container1 {
  line-break: anywhere;
  width: 100%;
}

.enjoy-our-special-container {
  position: absolute;
  top: -7px;
  left: 0;
  line-height: 42px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 311px;
  height: 89px;
}

.heading-2 {
  position: absolute;
  top: 247.75px;
  left: -0.49px;
  width: 318px;
  height: 76px;
  font-size: var(--font-size-16xl);
  color: var(--color-gray-400);
  font-family: var(--font-jost);
}

.unlock-an-exceptional {
  position: absolute;
  top: -1.51px;
  left: 0;
  line-height: 18.06px;
  display: flex;
  align-items: center;
  width: 200.37px;
  height: 38.38px;
}

.divelementor-widget-container {
  position: absolute;
  top: 338.7px;
  left: 0;
  width: 270.9px;
  height: 36.12px;
  font-size: 12.04px;
  color: var(--color-darkgray-100);
  font-family: var(--font-catamaran);
}

.divelementor-widget-wrap1 {
  position: absolute;
  width: calc(100% - 586.99px);
  top: 0;
  right: 625.99px;
  left: -39px;
  height: 628.49px;
}

.div12 {
  position: absolute;
  top: 29.35px;
  left: 30.1px;
  line-height: 30.1px;
  display: none;
  align-items: center;
  width: 30.25px;
  height: 30.85px;
}

.effortlessly-track-and,
.heading-43 {
  position: absolute;
  display: flex;
  align-items: center;
}

.heading-43 {
  top: -3.51px;
  left: -0.05px;
  line-height: 19.87px;
  font-weight: 500;
  width: 186px;
  height: 27px;
}

.effortlessly-track-and {
  top: -1.51px;
  left: 0;
  line-height: 18.06px;
  width: 203.31px;
  height: 56.44px;
}

.divicon-box,
.picon-box-description {
  position: absolute;
  top: 27.38px;
  left: 0;
  width: 214.46px;
  height: 54.18px;
  font-size: 12.04px;
  color: var(--color-darkgray-100);
}

.divicon-box {
  top: 75.25px;
  left: 30.1px;
  height: 81.57px;
  font-size: 16.56px;
  color: var(--color-gray-400);
  font-family: var(--font-catamaran);
}

.divjkit-icon-box-wrapper,
.inspection-1-icon {
  position: absolute;
  top: 22.75px;
  left: 30.05px;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.divjkit-icon-box-wrapper {
  top: 0;
  left: 0;
  border-radius: 7.53px;
  background-color: var(--color-white);
  box-shadow: 0 2.2576003074645996px 11.29px rgba(0, 0, 0, 0.1);
  width: 274.66px;
  height: 186.92px;
}

.div13 {
  top: 29.35px;
  left: 30.1px;
  line-height: 30.1px;
  width: 30.25px;
  height: 30.85px;
}

.build-strong-customer,
.div13,
.heading-51 {
  position: absolute;
  display: flex;
  align-items: center;
}

.heading-51 {
  top: -4px;
  left: -0.05px;
  line-height: 19.87px;
  font-weight: 500;
  width: 186px;
  height: 27px;
}

.build-strong-customer {
  top: 5.5px;
  left: 0;
  line-height: 18.06px;
  width: 203.31px;
  height: 56.44px;
}

.divicon-box1,
.picon-box-description1 {
  position: absolute;
  top: 27.38px;
  left: 0;
  width: 214.46px;
  height: 54.18px;
  font-size: 12.04px;
}

.divicon-box1 {
  top: 75.25px;
  left: 30.1px;
  height: 81.57px;
  font-size: 16.56px;
  font-family: var(--font-catamaran);
}

.customer-care-1-icon,
.divjkit-icon-box-wrapper1 {
  position: absolute;
  top: 18.25px;
  left: 30.05px;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.divjkit-icon-box-wrapper1 {
  top: 209.5px;
  left: 0;
  border-radius: 7.53px;
  background-color: var(--color-darkslategray-100);
  box-shadow: 0 0 11.29px rgba(0, 0, 0, 0.1);
  width: 274.66px;
  height: 186.92px;
  color: var(--color-white);
}

.gain-valuable-insights,
.heading-52 {
  position: absolute;
  display: flex;
  align-items: center;
}

.heading-52 {
  top: -3.5px;
  left: -0.05px;
  line-height: 19.87px;
  font-weight: 500;
  width: 175px;
  height: 27px;
}

.gain-valuable-insights {
  top: 4.49px;
  left: 0;
  line-height: 18.06px;
  width: 203.31px;
  height: 56.44px;
}

.divicon-box2 {
  position: absolute;
  top: 75.25px;
  left: 30.1px;
  width: 214.46px;
  height: 81.57px;
}

.divjkit-icon-box-wrapper2,
.report-3-1 {
  position: absolute;
  top: 16.75px;
  left: 30.05px;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.divjkit-icon-box-wrapper2 {
  top: 419px;
  left: 0;
  border-radius: 7.53px;
  background-color: var(--color-white);
  box-shadow: 0 2.2576003074645996px 11.29px rgba(0, 0, 0, 0.1);
  width: 274.66px;
  height: 186.92px;
  font-size: 16.56px;
  color: var(--color-gray-400);
  font-family: var(--font-catamaran);
}

.divelementor-widget-wrap2 {
  position: absolute;
  width: calc(100% - 583.23px);
  top: 0;
  right: 297.28px;
  left: 285.95px;
  height: 628.49px;
  font-size: var(--font-size-11xl-1);
  color: var(--color-darkslategray-100);
  font-family: var(--font-inter);
}

.heading-53 {
  position: absolute;
  top: -4.24px;
  left: -0.29px;
  line-height: 19.87px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 204px;
  height: 28px;
}

.picon-box-description3 {
  position: absolute;
  top: 27.39px;
  left: 0;
  width: 214.46px;
  height: 54.18px;
  font-size: 12.04px;
  color: var(--color-darkgray-100);
}

.basket-1-icon,
.divjkit-icon-box-wrapper3 {
  position: absolute;
  top: 24.01px;
  left: 29.81px;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.divjkit-icon-box-wrapper3 {
  top: 104.74px;
  left: 0;
  border-radius: 7.53px;
  background-color: var(--color-white);
  box-shadow: 0 2.2576003074645996px 11.29px rgba(0, 0, 0, 0.1);
  width: 274.66px;
  height: 186.92px;
}

.heading-54 {
  position: absolute;
  top: -3.76px;
  left: 0;
  line-height: 19.87px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 184.44px;
  height: 27.09px;
}

.divjkit-icon-box-wrapper4,
.shops-1-icon {
  position: absolute;
  top: 17.5px;
  left: 29.81px;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.divjkit-icon-box-wrapper4 {
  top: 314.24px;
  left: 0;
  border-radius: 7.53px;
  background-color: var(--color-white);
  box-shadow: 0 2.2576003074645996px 11.29px rgba(0, 0, 0, 0.1);
  width: 274.66px;
  height: 186.92px;
}

.divelementor-widget-wrap3 {
  position: absolute;
  width: calc(100% - 583.23px);
  top: 0;
  right: 0.04px;
  left: 583.19px;
  height: 628.49px;
  font-size: 16.56px;
  color: var(--color-gray-400);
  font-family: var(--font-catamaran);
}

.section5,
.section6 {
  position: absolute;
  top: 75.25px;
  left: calc(50% - 429.43px);
  width: 100%;
  height: 628.49px;
}

.section5 {
  top: 3700px;
  left: 0px;
  background-color: var(--color-white);
  width: 100%;
  height: 779px;
}

.pseudo1 {
  position: absolute;
  top: 0;
  left: 885px;
  border-radius: 6.47px 0 0 6.47px;
  width: 667.35px;
  height: 638.58px;
}

.choose-us-as {
  position: absolute;
  top: 3.23px;
  left: 0;
  line-height: 25.87px;
  display: flex;
  align-items: center;
  width: 374.28px;
  height: 70.32px;
}

.pwhy-choose-one-left-text {
  position: absolute;
  top: 157.63px;
  left: 0;
  width: 396.08px;
  height: 77.6px;
}

.benefits-of-product-container {
  position: absolute;
  top: -0.81px;
  left: 0;
  line-height: 42px;
  text-transform: capitalize;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 396.6px;
  height: 98.62px;
}

.heading-21,
.why-choose-us {
  position: absolute;
  top: 42.67px;
  left: 0.13px;
  width: 396.08px;
  height: 97px;
}

.why-choose-us {
  top: -3px;
  left: -23px;
  font-size: var(--font-size-9xl);
  line-height: 36px;
  font-weight: 600;
  font-family: var(--font-montserrat);
  color: var(--color-darkorange);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 288px;
  height: 30px;
}

.divsection-title {
  position: absolute;
  top: -8.34px;
  left: -0.12px;
  width: 396px;
  height: 138px;
  font-size: var(--font-size-16xl);
  color: var(--color-gray-300);
  font-family: var(--font-jost);
}

.icon2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 52.54px;
  height: 52.54px;
  overflow: hidden;
}

.trusted-and-reliable {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 299px;
  flex-shrink: 0;
}

.heading-44 {
  position: absolute;
  top: 4.72px;
  left: 69.13px;
  width: 299px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.divwhy-choose-one-left-learn {
  position: absolute;
  top: 265.94px;
  left: 0;
  width: 396.08px;
  height: 57.39px;
  font-size: 15px;
  color: var(--color-gray-300);
  font-family: var(--font-montserrat);
}

.icon3 {
  position: absolute;
  top: 2.42px;
  left: 0;
  width: 16.17px;
  height: 16.17px;
  overflow: hidden;
}

.trusted-expertise {
  position: absolute;
  top: 3.23px;
  left: 28.29px;
  line-height: 25.87px;
  display: flex;
  align-items: center;
  width: 269.89px;
  height: 18.59px;
}

.icon4,
.item9 {
  position: absolute;
  top: 0;
  left: 0;
  width: 396.08px;
  height: 25.87px;
}

.icon4 {
  top: 2.43px;
  width: 16.17px;
  height: 16.17px;
  overflow: hidden;
}

.innovative-solutions1 {
  position: absolute;
  top: 3.23px;
  left: 28.29px;
  line-height: 25.87px;
  display: flex;
  align-items: center;
  width: 261.75px;
  height: 18.59px;
}

.item10 {
  position: absolute;
  top: 32.33px;
  left: 0;
  width: 396.08px;
  height: 25.87px;
}

.seamless-integration {
  position: absolute;
  top: 3.23px;
  left: 28.29px;
  line-height: 25.87px;
  display: flex;
  align-items: center;
  width: 219.33px;
  height: 18.59px;
}

.item11,
.list2 {
  position: absolute;
  top: 64.67px;
  left: 0;
  width: 396.08px;
  height: 25.87px;
}

.list2 {
  top: 354.05px;
  height: 90.53px;
  overflow: hidden;
}

.divwhy-choose-one-left {
  position: absolute;
  width: calc(100% - 573.92px);
  top: 8.34px;
  right: 12.04px;
  left: 561.88px;
  height: 444.58px;
}

.image-2-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 611.71px;
  height: 397px;
  object-fit: cover;
}

.were-the-best {
  position: absolute;
  top: 18.59px;
  left: 76.79px;
  letter-spacing: 1.13px;
  line-height: 19.4px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 195.11px;
  height: 14.55px;
}

.divwhy-choose-one-right-img- {
  position: absolute;
  height: calc(100% - 396.08px);
  width: calc(100% - 112.22px);
  top: 48.02px;
  right: 63.24px;
  bottom: 348.06px;
  left: 48.98px;
  border-radius: 6.47px 6.47px 0 0;
  background-color: var(--color-darkslategray-100);
  transform: rotate(90deg);
  transform-origin: 0 0;
}

.divwhy-choose-one-right-img {
  position: absolute;
  top: 14px;
  left: 12.13px;
  width: 460.75px;
  height: 444.58px;
  overflow: hidden;
  font-size: var(--font-size-xs);
  color: var(--color-white);
}

.divrow1 {
  position: absolute;
  top: 83px;
  left: calc(50% - 485px);
  width: 970px;
  height: 459px;
  overflow: hidden;
}

.section7 {
  position: absolute;
  top: 4600px;
  left: calc(50% - 776px);
  width: 1552px;
  height: 638.58px;
  font-size: 14.55px;
  color: var(--color-slategray-200);
  font-family: var(--font-kumbh-sans);
}

.ansoftt-design-by {
  position: absolute;
  top: 1.6px;
  left: 0.34px;
  line-height: 20.88px;
  display: flex;
  align-items: center;
  width: 353px;
  height: 18px;
}

.ptext-white-50 {
  position: absolute;
  top: 3.48px;
  left: 10.44px;
  width: 234.96px;
  height: 20.88px;
}

.divrow2,
.list-icon {
  position: absolute;
  top: 0;
  left: 876.97px;
  width: 104.16px;
  height: 27.83px;
}

.divrow2 {
  top: 13.92px;
  left: 70%;
  width: 991.56px;
}

.divfooter {
  position: absolute;
  top: 6836px;
  /* left: calc(50% - 835px); */
  background-color: var(--color-darkslategray-100);
  width: 100%;
  height: 55.67px;
  font-size: 13.05px;
  color: rgba(255, 255, 255, 0.5);
  font-family: var(--font-nunito);
}

.we-provide-many-container {
  position: absolute;
  top: 0;
  left: 567.5px;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 214px;
  height: 39px;
}

.favicon-512x512-1 {
  position: absolute;
  top: 0;
  left: 48.5px;
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.ansoftt-pos {
  position: absolute;
  top: 16px;
  left: 120.5px;
  font-size: var(--font-size-21xl);
  letter-spacing: 0.1em;
  line-height: 48px;
  font-weight: 800;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 385px;
  color: white;
}

.arrow1png-icon {
  position: absolute;
  top: calc(50% - 228.93px);
  left: calc(50% + 159.41px);
  width: 38px;
  height: 43px;
  object-fit: cover;
}

.administrations {
  position: absolute;
  top: 129px;
  left: 553.5px;
  font-weight: 900;
  color: white;
}

.employee-management,
.pos-report,
.pos-sales-counter {
  position: absolute;
  top: 270px;
  left: 553.5px;
  font-weight: 900;
  color: white;
}

.employee-management,
.pos-report {
  top: 412px;
  color: white;
}

.employee-management {
  top: 203px;
  left: 55.5px;
}

.purchasing-operations,
.stock-management {
  position: absolute;
  top: 343px;
  left: 35.5px;
  font-weight: 900;
  color: white;
}

.stock-management {
  top: 485px;
  left: 102.5px;
}

.group-icon1 {
  position: absolute;
  height: 84.43%;
  width: 100%;
  top: 15.57%;
  right: -0.75%;
  bottom: 0;
  left: 0.75%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.favicon-512x512-1-parent {
  position: absolute;
  top: 40px;
  left: 0;
  width: 870px;
  height: 561.86px;
  text-align: left;
  font-size: var(--font-size-5xl);
  font-family: var(--font-jost);
}

.we-provide-many-types-of-featu-parent {
  position: absolute;
  top: 40px;
  left: -6.5px;
  width: 870px;
  height: 562.86px;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-white);
  font-family: var(--font-segoe-print);
}

.favicon-512x512-11 {
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.ansoftt-pos1 {
  position: absolute;
  top: 16px;
  left: 66px;
  letter-spacing: 0.1em;
  line-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 385px;
}

.header2 {
  position: absolute;
  top: 10px;
  left: 36px;
  width: 451px;
  height: 70px;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--color-darkslategray-100);
}

.pseudo2 {
  position: absolute;
  top: 273.57px;
  left: 0;
  background-color: var(--color-white);
  width: 100%;
  height: 319.41px;
}

.divsection-title1 {
  position: absolute;
  top: 0;
  left: 11.27px;
  width: 1104.8px;
  height: 75.16px;
}

.testimonials-one-left-pattern-icon,
.thm-shape3png-icon {
  position: absolute;
  top: calc(50% - 322.73px);
  object-fit: cover;
}

.testimonials-one-left-pattern-icon {
  left: -169.1px;
  width: 623.8px;
  height: 608.77px;
  opacity: 0.15;
}

.thm-shape3png-icon {
  left: 913.15px;
  width: 435.91px;
  height: 435.91px;
}

.as-your-business,
.heading-45 {
  position: absolute;
  top: 35.08px;
  left: 37.9px;
  line-height: 21.04px;
  display: flex;
  align-items: center;
  width: 199px;
  height: 18px;
}

.as-your-business {
  top: 13.01px;
  left: -2px;
  line-height: 24.05px;
  font-weight: 300;
  width: 276.39px;
  height: 89.44px;
}

.ptestimonials-one-single-tex {
  position: absolute;
  top: 72.15px;
  left: 38.33px;
  width: 276.58px;
  height: 96.2px;
  color: var(--color-slategray-200);
}

.divtestimonials-one-single-i {
  position: absolute;
  top: 0;
  left: 1878.91px;
  border-radius: 6.01px;
  background-color: var(--color-white);
  box-shadow: 0 7.515625476837158px 45.09px rgba(0, 0, 0, 0.07);
  width: 353.23px;
  height: 263.8px;
}

.heading-46,
.our-pos-system {
  position: absolute;
  top: 35.08px;
  left: 38.12px;
  line-height: 21.04px;
  display: flex;
  align-items: center;
  width: 175px;
  height: 18px;
}

.our-pos-system {
  top: 34.01px;
  left: 0;
  line-height: 24.05px;
  font-weight: 300;
  width: 276.39px;
  height: 89.44px;
}

.divtestimonials-one-single-i1 {
  position: absolute;
  top: 0;
  left: 2254.69px;
  border-radius: 6.01px;
  background-color: var(--color-white);
  box-shadow: 0 7.515625476837158px 45.09px rgba(0, 0, 0, 0.07);
  width: 353.23px;
  height: 263.8px;
}

.gain-valuable-insights1,
.heading-47 {
  position: absolute;
  top: 35.08px;
  left: 38.34px;
  line-height: 21.04px;
  display: flex;
  align-items: center;
  width: 266px;
  height: 18px;
}

.gain-valuable-insights1 {
  top: 46.01px;
  left: 0;
  line-height: 24.05px;
  font-weight: 300;
  width: 276.39px;
  height: 89.44px;
}

.divtestimonials-one-single-i2 {
  position: absolute;
  top: 0;
  left: 2630.47px;
  border-radius: 6.01px;
  background-color: var(--color-white);
  box-shadow: 0 7.515625476837158px 45.09px rgba(0, 0, 0, 0.07);
  width: 353.23px;
  height: 263.8px;
}

.divowl-stage {
  position: absolute;
  top: 0;
  left: -1878.91px;
  width: 5636.72px;
  height: 263.8px;
}

.button1,
.button2,
.button3 {
  position: absolute;
  top: 45.09px;
  left: calc(50% - 24.43px);
  border-radius: 5.64px;
  background-color: var(--color-darkslategray-100);
  width: 11.27px;
  height: 11.27px;
}

.button2,
.button3 {
  left: calc(50% - 5.64px);
  background-color: var(--color-whitesmoke);
}

.button3 {
  left: calc(50% + 13.15px);
}

.divowl-dots {
  position: absolute;
  top: 263.8px;
  left: 0;
  width: 1104.8px;
  height: 56.37px;
}

.divtestimonials-one-carousel {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  height: 320.17px;
}

.divtestimonials-one-wrapper {
  position: absolute;
  top: 77.72px;
  left: 11.27px;
  width: 1104.8px;
  height: 320.17px;
}

.divauto-container {
  position: absolute;
  top: 84.93px;
  left: calc(50% - 563.67px);
  width: 1127.34px;
  height: 426.89px;
}

.our-product-usp {
  position: absolute;
  top: 57.73px;
  left: 560px;
  font-size: var(--font-size-9xl);
  line-height: 36px;
  font-weight: 600;
  font-family: var(--font-montserrat);
  color: var(--color-darkorange);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 288px;
  height: 30px;
}

.section8 {
  position: absolute;
  top: 5350px;
  left: 0;
  background-color: var(--color-darkslategray-100);
  width: 100%;
  height: 592.98px;
  overflow: hidden;
  font-size: 13.53px;
  color: var(--color-darkslategray-100);
  font-family: var(--font-kumbh-sans);
}

.testimonials-one-left-pattern-icon1 {
  position: absolute;
  top: calc(50% + 2208px);
  left: 0;
  width: 623.8px;
  height: 608.77px;
  object-fit: cover;
  opacity: 0.15;
}

.frequently-asked-questions {
  position: relative;
  top: -16px;
  left: calc(50% - 204px);
  line-height: 36px;
  font-weight: 600;
}

.have-any-questions {
  position: absolute;
  top: 50px;
  left: 56.75px;
  font-size: var(--font-size-8xl);
  line-height: 36px;
  font-family: var(--font-open-sans);
  color: var(--color-gray-200);
}

.heading-34 {
  position: absolute;
  top: 29px;
  left: calc(50% - 200px);
  border-bottom: 0.8px solid var(--color-gray-200);
  box-sizing: border-box;
  width: 399.71px;
  height: 91.5px;
}

.pseudo3 {
  position: absolute;
  top: -1px;
  left: 0;
  background: linear-gradient(90deg, #ff932e, #36474f);
  width: 970px;
  height: 226px;
}

.yes-absolutely-ansoftt {
  position: absolute;
  top: 96.81px;
  left: 61px;
  line-height: 34px;
  font-weight: 500;
  width: 754px;
  height: 90px;
}

.div19,
.is-ansoftt-pos,
.yes-absolutely-ansoftt {
  display: flex;
  align-items: center;
}

.is-ansoftt-pos {
  position: absolute;
  top: 0.31px;
  left: 60px;
  line-height: 24px;
  font-weight: 600;
  width: 659px;
  height: 24px;
}

.div19 {
  top: 0;
  left: 0;
  line-height: 34px;
  justify-content: center;
  width: 57.2px;
  height: 57px;
  font-size: 26px
}

.div19-2 {
  top: 0;
  left: 0;
  line-height: 55px;
  justify-content: center;
  width: 57px;
  height: 57px;
  font-size: 36px
}

.div18,
.div19,
.pseudo4 {
  position: absolute;
}

.pseudo4 {
  top: -16.5px;
  left: 849px;
  border-radius: var(--br-9xl-5);
  background-color: var(--color-darkorange);
  width: 57px;
  height: 57px;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
}

.div18 {
  top: 31.5px;
  left: 1px;
  width: 966px;
  height: 24px;
  font-size: var(--font-size-xl);
  color: var(--color-darkslategray-200);
  font-family: var(--font-montserrat);
}

.div16,
.div17 {
  position: relative;
  top: 0;
  height: 224px;
}

.div17 {
  left: 1px;
  background-color: var(--color-white);
  border: 1px solid var(--color-ghostwhite);
  box-sizing: border-box;
  width: 968px;
}

.div16 {
  left: 0;
  background-color: transparent;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.04);
  width: 970px;
  font-size: var(--font-size-lg);
  color: #7c7d8a;
  font-family: var(--font-kumbh-sans);
}

.can-ansoftt-pos {
  position: absolute;
  top: 0.31px;
  left: 60px;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 683px;
  height: 24px;
}

.pseudo5 {
  position: absolute;
  top: -16.5px;
  left: 851px;
  border-radius: var(--br-9xl-5);
  /* background-color: var(--color-darkslategray-100); */
  width: 57px;
  height: 57px;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
}

.div20,
.div21 {
  position: relative;
  /* top: 31.5px; */
  left: 1px;
  width: 968px;
  height: 24px;
}

.div20 {
  /* top: 244px; */
  left: 0;
  background-color: var(--color-white);
  border: 1px solid var(--color-ghostwhite);
  box-sizing: border-box;
  width: 970px;
  height: 87px;
}


.div16-2 {
  position: absolute;
  top: 31.5px;
  left: 1px;
  width: 968px;
  height: 24px;
}

.div16-2 {
  /* top: 244px; */
  left: 0;
  background-color: var(--color-white);
  border: 1px solid var(--color-ghostwhite);
  box-sizing: border-box;
  width: 970px;
  height: 87px;
}

.how-easy-is {
  top: 0.31px;
  left: 60px;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 513px;
  height: 24px;
}

.div23,
.how-easy-is,
.pseudo6 {
  position: absolute;
}

.pseudo6 {
  top: -16.5px;
  left: 851px;
  border-radius: var(--br-9xl-5);
  background-color: var(--color-darkslategray-200);
  width: 57px;
  height: 57px;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-font-awesome-5-free);
}

.div23 {
  top: 351px;
  left: 0;
  background-color: var(--color-white);
  border: 1px solid var(--color-ghostwhite);
  box-sizing: border-box;
  width: 970px;
  height: 87px;
}

.div17-2 {
  position: relative;
  /* top: 351px; */
  left: 0;
  background-color: var(--color-white);
  border: 1px solid var(--color-ghostwhite);
  box-sizing: border-box;
  width: 970px;
  height: 87px;
}

.spacer {
  height: 30px;
}

.what-type-of {
  position: absolute;
  top: 0.31px;
  left: 60px;
  line-height: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 767px;
  height: 24px;
}

.div26 {
  top: 458px;
  left: 0;
  background-color: var(--color-white);
  border: 1px solid var(--color-ghostwhite);
  box-sizing: border-box;
  width: 970px;
  height: 87px;
}

.div14,
.div15,
.div26 {
  position: absolute;
}

.div15 {
  top: 181.19px;
  left: 15px;
  width: 970px;
  height: 545px;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-gray-100);
  flex-direction: column;
}

.div14 {
  top: 5920px;
  left: calc(50% - 490px);
  width: 1000px;
  height: 739px;
  text-align: center;
  font-size: var(--font-size-9xl);
  flex-direction: column;
}

.desktop-10 {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 6890px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-8xl);
  color: var(--color-darkorange);
  font-family: var(--font-montserrat);
}

.row {
  display: flex;
  flex-direction: row;
}

.social-links {
  height: 30px;
  width: 30px;
  justify-content: right;
  margin-left: 10px;
}

.center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}