@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@600;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Font+Awesome+5+Free:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Segoe+Print:wght@700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-font-awesome-5-free: "Font Awesome 5 Free";
  --font-montserrat: Montserrat;
  --font-kumbh-sans: "Kumbh Sans";
  --font-open-sans: "Open Sans";
  --font-jost: Jost;
  --font-segoe-print: "Segoe Print";
  --font-nunito: Nunito;
  --font-catamaran: Catamaran;
  --font-inter: Inter;
  --font-roboto: Roboto;
  --font-lato: Lato;

  /* font sizes */
  --font-size-sm: 14px;
  --font-size-xl: 20px;
  --font-size-lg: 18px;
  --font-size-8xl: 27px;
  --font-size-9xl: 28px;
  --font-size-sm-5: 13.5px;
  --font-size-21xl: 40px;
  --font-size-5xl: 24px;
  --font-size-xs: 12px;
  --font-size-mini-6: 14.6px;
  --font-size-16xl: 35px;
  --font-size-base-6: 16.6px;
  --font-size-11xl-1: 30.1px;
  --font-size-base: 16px;
  --font-size-smi-8: 12.8px;
  --font-size-24xl-8: 43.8px;
  --font-size-sm-6: 13.6px;

  /* Colors */
  --color-white: #fff;
  --color-ghostwhite: #e8e8ef;
  --color-darkslategray-100: #36474f;
  --color-darkslategray-200: #373f46;
  --color-darkslategray-300: rgba(54, 71, 79, 0.6);
  --color-darkslategray-400: rgba(54, 71, 79, 0.23);
  --color-gray-100: #30283d;
  --color-gray-200: #222;
  --color-gray-300: #1c1e27;
  --color-gray-400: #0d0b0b;
  --color-darkorange: #ff932e;
  --color-slategray-100: #6f8197;
  --color-slategray-200: #757783;
  --color-whitesmoke: #f1f2f6;
  --color-darkgray-100: #a0a0a0;
  --color-darkgray-200: #949fa8;
  --color-firebrick: #cd4236;

  /* Paddings */
  --padding-base-5: 16.5px;

  /* border radiuses */
  --br-9xl-5: 28.5px;
  --br-7xs-6: 5.6px;
  --br-7xs: 6px;
  --br-6xs-5: 6.5px;
  --br-5xs-5: 7.5px;
  --br-10xs: 3px;
  --br-xs-3: 11.3px;
  --br-8xs-5: 4.5px;
  --br-11xl-2: 30.2px;
}
